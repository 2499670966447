/*
 * @Description: cookie工具类
 * @Autor: 池樱千幻
 * @Change: 池樱千幻
 * @Date: 2023-02-01 15:52:35
 * @LastEditTime: 2023-02-03 13:47:31
 */
import Cookies from 'js-cookie';

const TokenKey = 'isCapital';

export function getToken(key) {
  let result = Cookies.get(`${TokenKey}-${key}`);
  try {
    result = JSON.parse(result);
  } catch (error) {
    //
  }
  return result;
}

export function setToken(key, obj) {
  if (typeof obj === 'object') {
    obj = JSON.stringify(obj);
  }

  return Cookies.set(`${TokenKey}-${key}`, obj);
}

export function removeToken(key) {
  return Cookies.remove(`${TokenKey}-${key}`);
}
