<!--  -->
<template>
  <div ref="footer" class="footer">
    <div class="partner i-fixedWidth">
      <transition name="under-into">
        <div class="title" v-if="showFooter[0]">{{ $t('Main.Main98') }}</div>
      </transition>
      <transition name="under-into">
        <div v-show="showFooter[1]" class="partnerContent u-flex u-row-between">
          <template v-for="(src, index) in partnerImgList">
            <div v-if="index <= 4" :key="index" class="partnersItem"><img :src="src" /></div>
          </template>
        </div>
      </transition>

      <transition name="under-into">
        <div v-show="showFooter[2]" class="partnerContent u-flex u-row-between">
          <template v-for="(src, index) in partnerImgList">
            <div v-if="index > 4" :key="index" class="partnersItem"><img :src="src" /></div>
          </template>
        </div>
      </transition>
    </div>
    <div class="disclaimerContent">
      <div class="disclaimer i-fixedWidth">
        <div class="disclaimer-title">{{ $t('Main.Main99') }}</div>
        <div class="disclaimer-subTitle subTitleFontFamily">{{ $t('Main.Main100') }}</div>
        <div class="disclaimer-subTitle">
          Disclaimer of  jurisdiction: We don't provide services to residents of USA, Afghanistan, Barbados, Burkina Faso, Cambodia, Cayman Islands, Democratic People's Republic of Korea (DPRK), Haiti, Iran, Jamaica, Jordan, Mali, Morocco, Myanmar, Nicaragua, Pakistan, Panama, the Philippines, Senegal, South Sudan, Syria, Trinidad and Tobago, Uganda, Vanuatu, and SVG.
        </div>

        <div class="u-flex">
          <img :src="$t('image.footerLogo')" />
          <div class="nav">
            <div>
              <a href="https://www.is.capital/resource/risk.pdf">{{ $t('Main.Main101') }}</a> | <a href="https://www.is.capital/resource/anti.pdf">{{ $t('Main.Main102') }}</a> | <a href="https://www.is.capital/resource/privacy.pdf">{{ $t('Main.Main103') }}</a> | <a href="https://www.is.capital/resource/client.pdf">{{ $t('Main.Main104') }}</a> <br />
              <router-link to="/contact" >{{ $t('Main.Main105') }}</router-link>

            </div>
            <div class="email">
              <div>{{ $t('Main.Main106') }}</div>
              <div>{{ $t('Main.Main107') }}</div>
              <div>{{ $t('Main.Main108') }}</div>
              <div>Phone: +61 (03) 7068 9989</div>
            </div>
          </div>
        </div>
        <div > This website is operated by Intercontinental Securities LLC. Intercontinental Securities LLC is located in Suite 305, Griffith Corporate Center, Kingstown, St. Vincent and the Grenadines. Intercontinental Securities LLC is regulated by Financial Service Authority (FSA) with registration number 683LLC2020. </div>
      </div>
    </div>

    <div class="copyright">{{ $t('copyright') }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { scrollMixins } from '@/utils/mixins';
let debuggerFLag = false
export default {
  mixins: [scrollMixins],
  data() {
    return {
      partnerImgList: [],
      showFooter: [debuggerFLag, debuggerFLag, debuggerFLag],
    };
  },

  components: {},

  computed: {
    ...mapGetters(['json']),
  },
  watch: {
    json: {
      handler: function (json) {
        this.partnerImgList = json.partner;
      },
      immediate: true,
    },
  },
  mounted() {
    // console.log(this.json);
    this.handleScroll();
  },

  methods: {
    handleScroll() {
      let footerShow = this.isElementsVisible(this.$refs.footer);
      if (footerShow) {
        this.animationList(this.showFooter);
      } else {
        this.showFooter = this.$options.data().showFooter;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.footer {
  width: 100%;
  background-color: #fff;


  .partner {
    height: 4rem;
    background-color: #fff;
    margin-top: .8445rem;
    overflow: hidden;

    .partnerContent {
      width: 8.7755rem;
      margin: .42rem auto;
      .partnersItem {
        flex: 1;
        margin-right: .3rem;
      }
      img {
        width: 100%;
      }
    }
    .title {
      text-align: center;
      margin-bottom: .72rem;
      font-size: .37rem;
      @extend .fontRobotoBold;
      font-weight: bold;
      color: #000810;
      line-height: .42rem;
    }
  }
  .disclaimerContent {
    background-color: #f6f6f6;
  }

  .disclaimer {
    padding-top: .8rem;
    padding-bottom: .2rem;
    width: 11rem;
    .disclaimer-title {
      font-size: .14rem;
      font-weight: 700;
      margin-bottom: .08rem;
      @extend .fontRobotoBold;
    }
    .disclaimer-subTitle {
      color: #333;
      @extend .fontOsLight;
      font-size: .12rem;
    }
    img {
      width: 1.5rem;
    }
  }
  .nav {
    padding: .4rem;
    padding-bottom: .2rem;
    font-size: .14rem;
    @extend .fontRobotoRegular;
    a {
      color: #337ab7;
      &:hover {
        color: #23527c;
      }
    }
  }
  .email {
    margin-top: .15rem;
    line-height: .2rem;
    color: #333;
    font-size: .12rem;
  }
  .copyright {
    background-color: #001649;
    display: flex;
    justify-content: center;
    align-items: center;
    height: .3rem;
    font-size: .1rem;
    color: rgb(210, 210, 210);
  }
}
</style>
