import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/home.vue'),
  },
  {
    path: '/forex',
    name: 'forex',
    component: () => import(/* webpackChunkName: "markets" */ '../views/markets/forex.vue'),
  },
  {
    path: '/shares',
    name: 'shares',
    component: () => import(/* webpackChunkName: "markets" */ '../views/markets/shares.vue'),
  },
  {
    path: '/crypto',
    name: 'crypto',
    component: () => import(/* webpackChunkName: "markets" */ '../views/markets/crypto.vue'),
  },
  {
    path: '/indices',
    name: 'indices',
    component: () => import(/* webpackChunkName: "markets" */ '../views/markets/indices.vue'),
  },
  {
    path: '/preciousMetals',
    name: 'preciousMetals',
    component: () => import(/* webpackChunkName: "markets" */ '../views/markets/preciousMetals.vue'),
  },
  {
    path: '/energies',
    name: 'energies',
    component: () => import(/* webpackChunkName: "markets" */ '../views/markets/energies.vue'),
  },
  {
    path: '/etfs',
    name: 'etfs',
    component: () => import(/* webpackChunkName: "markets" */ '../views/markets/etfs.vue'),
  },

  {
    path: '/windows',
    name: 'windows',
    component: () => import(/* webpackChunkName: "platforms" */ '../views/platforms/platformsMT5.vue'),
  },
  {
    path: '/mac',
    name: 'mac',
    component: () => import(/* webpackChunkName: "platforms" */ '../views/platforms/platformsMT5.vue'),
  },
  {
    path: '/android',
    name: 'android',
    component: () => import(/* webpackChunkName: "platforms" */ '../views/platforms/platformsMT5.vue'),
  },
  {
    path: '/ios',
    name: 'ios',
    component: () => import(/* webpackChunkName: "platforms" */ '../views/platforms/platformsMT5.vue'),
  },
  {
    path: '/ctraderWindows',
    name: 'ctraderWindows',
    component: () => import(/* webpackChunkName: "platforms" */ '../views/platforms/platformsCtrader.vue'),
  },
  {
    path: '/ctraderIos',
    name: 'ctraderIos',
    component: () => import(/* webpackChunkName: "platforms" */ '../views/platforms/platformsCtrader.vue'),
  },
  {
    path: '/ctraderAndroid',
    name: 'ctraderAndroid',
    component: () => import(/* webpackChunkName: "platforms" */ '../views/platforms/platformsCtrader.vue'),
  },
  {
    path: '/accountsOverview',
    name: 'accountsOverview',
    component: () => import(/* webpackChunkName: "tradeWithUs" */ '../views/tradeWithUs/accountsOverview.vue'),
  },
  {
    path: '/extra',
    name: 'extra',
    component: () => import(/* webpackChunkName: "tradeWithUs" */ '../views/tradeWithUs/accountsOverview.vue'),
  },
  {
    path: '/gift',
    name: 'gift',
    component: () => import(/* webpackChunkName: "tradeWithUs" */ '../views/tradeWithUs/accountsOverview.vue'),
  },

  {
    path: '/follow',
    name: 'follow',
    component: () => import(/* webpackChunkName: "investment" */ '../views/investment/follow.vue'),
  },
  {
    path: '/VIX',
    name: 'VIX',
    component: () => import(/* webpackChunkName: "investment" */ '../views/investment/VIX.vue'),
  },

  {
    path: '/education',
    name: 'education',
    component: () => import(/* webpackChunkName: "investment" */ '../views/investment/education.vue'),
  },
  {
    path: '/legal',
    name: 'legal',
    component: () => import(/* webpackChunkName: "joinUs" */ '../views/joinUs/legal.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "joinUs" */ '../views/joinUs/about.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "joinUs" */ '../views/joinUs/contact.vue'),
  },
  {
    path: '/intro',
    name: 'intro',
    component: () => import(/* webpackChunkName: "joinUs" */ '../views/joinUs/intro.vue'),
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return process.env.NODE_ENV === 'production' ? { x: 0, y: 0 } : savedPosition;
  },
});

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
